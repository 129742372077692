import { createSlice } from '@reduxjs/toolkit';
import { getAppUsage, updateAppUsage } from 'api/api';

const initialState = {
  loading: false,
  data: {},
  error: null
};

const appUsageSlice = createSlice({
  name: 'appUsage',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAppUsage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppUsage.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getAppUsage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    });
    builder.addCase(updateAppUsage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAppUsage.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateAppUsage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    });
  }
});

export default appUsageSlice.reducer;
