import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeStep: 0,
  isEdit: false
};

const StepperSlice = createSlice({
  name: 'Stepper',
  initialState,
  reducers: {
    SetStep: (state) => ({
      ...state,
      activeStep: Number(state.activeStep) + 1
    }),
    changeStep: (state, action) => ({
      ...state,
      activeStep: Number(action.payload)
    }),
    backStep: (state) => ({
      ...state,
      activeStep: Number(state.activeStep) - 1
    }),
    clearStep: (state) => ({
      ...state,
      activeStep: 0
    }),
    SetIsEditTrue: (state, action) => ({
      ...state,
      isEdit: action.payload
    })
  }
});

export const { SetStep, backStep, clearStep, changeStep, SetIsEditTrue } = StepperSlice.actions;
export default StepperSlice.reducer;
