import { createSlice } from '@reduxjs/toolkit';
import { getInputs } from '../../api/api';

const initialState = {
  data: [],
  loading: true,
  error: ''
};

const InputSlice = createSlice({
  name: 'inputs',
  initialState,
  reducers: {
    clearInputs: (state) => {
      state.data = [];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getInputs.pending, (state) => {
        state.data = [];
        state.loading = true;
      })
      .addCase(getInputs.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getInputs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      });
  }
});

export const { clearInputs } = InputSlice.actions;
export default InputSlice.reducer;
