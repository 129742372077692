import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  assignee: []
};

const decisionFilterSlice = createSlice({
  name: 'decisionFilter',
  initialState,
  reducers: {
    applyFilter: (state, action) => {
      const { assignee } = action.payload;
      state.assignee = assignee;
    },
    removeFilter: (state, action) => {
      const { type, val } = action.payload;
      if (type === 'date') {
        state[type] = '';
      } else state[type] = state[type].filter((item) => item !== val);
    },
    clearAll: (state) => {
      state.assignee = [];
    }
  }
});

export const { applyFilter, clearAll, removeFilter } = decisionFilterSlice.actions;
export default decisionFilterSlice.reducer;
