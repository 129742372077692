import { createSlice } from '@reduxjs/toolkit';
import { getReports } from '../../api/api';

const initialState = {
  data: [],
  trackerInsights: [],
  loading: false,
  error: ''
};

const ReportBuilderSlice = createSlice({
  name: 'Reports',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReports.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        if (action.payload.status === 'submitted') {
          state.trackerInsights = action.payload.data;
        } else {
          state.data = action.payload.data;
        }
      })
      .addCase(getReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data;
        state.data = [];
      });
  }
});

export default ReportBuilderSlice.reducer;
