import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const TableFilterSlice = createSlice({
  name: 'TableFilter',
  initialState,
  reducers: {
    applyFilter: (state, action) => {
      const { type, val } = action.payload;
      const dd = {};
      dd[type] = val;
      return { ...state, ...dd };
    },
    removeFilter: (state, action) => {
      const { type, val } = action.payload;

      const next = state;
      delete next[type][val];
      return next;
      // return state;
    },
    clearAll: (state, action) => {
      // state[action.payload.type] = {};
      const next = { ...state };
      delete next[action.payload.type];
      return next;
    },
    removeAllFilter: (state) => {
      state = {};
      return state;
    }
  }
});

export const { applyFilter, clearAll, removeFilter, removeAllFilter } = TableFilterSlice.actions;
export default TableFilterSlice.reducer;
