import { createSlice } from '@reduxjs/toolkit';
import { getCategoryDetails } from '../../api/api';

const initialState = {
  data: [],
  loading: true,
  error: '',
  selectedCard: {},
  selectedId: null
};

const CategoryDetailsSlice = createSlice({
  name: 'category_details',
  initialState,
  reducers: {
    setSelectedCard: (state, action) => ({
      ...state,
      selectedCard: action.payload
    }),
    setSelectedId: (state, action) => ({
      ...state,
      selectedId: action.payload
    })
  },
  extraReducers(builder) {
    builder
      .addCase(getCategoryDetails.pending, (state) => {
        state.data = [];
        state.loading = true;
      })
      .addCase(getCategoryDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getCategoryDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      });
  }
});
export const { setSelectedCard, setSelectedId } = CategoryDetailsSlice.actions;
export default CategoryDetailsSlice.reducer;
