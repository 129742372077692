import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectValue: 'last90',
  paidAsset: true
};

const dateSelectSlice = createSlice({
  name: 'dateSelectCustom',
  initialState,
  reducers: {
    setCustomDates: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.selectValue = action.payload.selectValue;
    },
    paidAssetToggle: (state) => {
      state.paidAsset = !state?.paidAsset;
      console.log(state.paidAsset);
    }
  }
});

export const { setCustomDates, paidAssetToggle } = dateSelectSlice.actions;
export default dateSelectSlice.reducer;
