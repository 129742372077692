import { createSlice } from '@reduxjs/toolkit';
import { getBannerDetails } from '../../api/api';

const initialState = {
  data: {},
  loading: false,
  error: ''
};

const BannerDetailsSlice = createSlice({
  name: 'banner-details',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getBannerDetails.pending, (state) => {
        state.data = {};
        state.loading = true;
      })
      .addCase(getBannerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getBannerDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = {};
      });
  }
});

export default BannerDetailsSlice.reducer;
