/* eslint-disable */
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { addNotification } from 'store/slices/NotificationSlice';

// const id = uuidv4();
let isNotificationInProgress = false;

export const rtkQueryErrorLogger = ({ dispatch }) => {
  return (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (!isNotificationInProgress) {
        // let errorMessage = `Error: ${action?.type}`;

        // todo : Add more cases to customize the error message

        if (action?.payload?.response?.status === 401) {
          isNotificationInProgress = true;
          dispatch(addNotification({ message: 'Session Expired, Please Login Again!', type: 'error', delay: 3000, id }));
        }
        setTimeout(() => {
          isNotificationInProgress = false;
        }, 3000);
      }
    }
    return next(action);
  };
};
