import { createSlice } from '@reduxjs/toolkit';
import { setDecisionDetail, updateAssignee, updateOutcome, updateStatus } from 'api/api';

const initialState = {
  loading: false,
  data: {},
  assignedTo: {},
  insightStatus: '',
  error: null
};

const decisionDetailSlice = createSlice({
  name: 'decision/detail',
  initialState,
  reducers: {
    updateAssigneeManually: (state, action) => {
      state.assignedTo = action.payload;
    },
    updateStatusManually: (state, action) => {
      state.insightStatus = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(setDecisionDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setDecisionDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.assignedTo = action.payload?.data?.assignee || {};
      state.insightStatus = action.payload?.data?.recommendationStatus || '';
      state.error = null;
    });
    builder.addCase(setDecisionDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    });
    builder.addCase(updateAssignee.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAssignee.fulfilled, (state, action) => {
      state.loading = false;
      state.assignedTo = action.payload?.data?.assignee || {};
      state.error = null;
    });
    builder.addCase(updateAssignee.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    });
    builder.addCase(updateStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.insightStatus = action.payload?.data?.recommendationStatus || '';
      state.error = null;
    });
    builder.addCase(updateStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    });
    builder.addCase(updateOutcome.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOutcome.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.data.latestOutcome = action.payload?.data?.latestOutcome;
    });
    builder.addCase(updateOutcome.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    });
  }
});

export const { updateAssigneeManually, updateStatusManually } = decisionDetailSlice.actions;
export default decisionDetailSlice.reducer;
