import { createSlice } from '@reduxjs/toolkit';
import { ToggleUserEnable, getAccessData } from '../../api/api';

const initialState = {
  data: {
    columns: [],
    data: [],
    page_size: 0,
    page_number: 0,
    totalCount: 0,
    totalPages: 0
  },
  loading: false,
  error: ''
};
// action.payload.data
// state.data.data
const accountStatusUpdater = (prevData, updatedUserId, status) => {
  // Todo : optimize the updation speed (maybe using index)
  prevData.forEach((obj) => {
    if (obj.id === updatedUserId) {
      obj.accountEnabled = status;
    }
  });
  return prevData;
};

const AccessDataSlice = createSlice({
  name: 'Access',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAccessData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAccessData.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.error = null;
          state.data = action?.payload || {
            columns: [],
            data: [],
            page_size: 0,
            page_number: 0,
            totalCount: 0,
            totalPages: 0
          };
        }
      })
      .addCase(getAccessData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = {
          columns: [],
          data: [],
          page_size: 0,
          page_number: 0,
          totalCount: 0,
          totalPages: 0,
          source_info: {}
        };
      })
      .addCase(ToggleUserEnable.pending, (state) => {
        state.loading = true;
      })
      .addCase(ToggleUserEnable.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const updatedState = accountStatusUpdater(
          state.data.data,
          action.payload?.data?.data?.id,
          action.payload?.data?.data?.accountEnabled
        );
        state.data.data = updatedState;
      })
      .addCase(ToggleUserEnable.rejected, (state) => {
        state.loading = false;
        state.data = {
          columns: [],
          data: [],
          page_size: 0,
          page_number: 0,
          totalCount: 0,
          totalPages: 0,
          source_info: {}
        };
        state.error = action?.error?.message;
      });
  }
});

export default AccessDataSlice.reducer;
