import { createSlice } from '@reduxjs/toolkit';
import { getServices } from '../../api/api';

const initialState = {
  data: [],
  loading: false,
  error: ''
};

const ServicesSlice = createSlice({
  name: 'Services',
  initialState,
  reducers: {
    clearData: (state) => {
      state.data = [];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getServices.pending, (state) => {
        state.data = [];
        state.loading = true;
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      });
  }
});
export const { clearData } = ServicesSlice.actions;
export default ServicesSlice.reducer;
