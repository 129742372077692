import { createSlice } from '@reduxjs/toolkit';
import { fetchDetailedTable, getDetailedTable } from '../../api/api';

const initialState = {
  data: {
    columns: [],
    data: [],
    page_size: 0,
    page_number: 0,
    totalCount: 0,
    totalPages: 0
  },
  tableTitle: '',
  loading: false,
  error: ''
};

const latestCardDetails = createSlice({
  name: 'Card Details',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDetailedTable.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDetailedTable.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload.data;
        if (action.payload.tableTitle) {
          state.tableTitle = action.payload.tableTitle;
        }
      })
      .addCase(fetchDetailedTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = {
          columns: [],
          data: [],
          page_size: 0,
          page_number: 0,
          totalCount: 0,
          totalPages: 0
        };
      })
      // For the new flow
      .addCase(getDetailedTable.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDetailedTable.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.error = null;
          state.data = action?.payload?.data?.data || {
            columns: [],
            data: [],
            page_size: 0,
            page_number: 0,
            totalCount: 0,
            totalPages: 0
          };
          if (action.payload.tableTitle) {
            state.tableTitle = action?.payload?.tableTitle;
          }
        } else {
          state.loading = false;
          state.error = null;
          state.data = {
            columns: [],
            data: [],
            page_size: 0,
            page_number: 0,
            totalCount: 0,
            totalPages: 0
          };
        }
      })
      .addCase(getDetailedTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = {
          columns: [],
          data: [],
          page_size: 0,
          page_number: 0,
          totalCount: 0,
          totalPages: 0
        };
      });
  }
});

export default latestCardDetails.reducer;
