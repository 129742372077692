import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  Name: [],
  'Company Name': [],
  Departments: [],
  'IT Verified': [],
  Sector: [],
  Owner: [],
  'Src of info': [],
  Status: []
};

const assetFilterSlice = createSlice({
  name: 'assetFilter',
  initialState,
  reducers: {
    applyFilter: (state, action) => {
      return { ...state, ...action.payload };
    },
    removeFilter: (state, action) => {
      const { type, val } = action.payload;
      state[type] = state[type].filter((item) => item !== val);
    },
    clearAll: (state) => {
      return { ...state, ...initialState };
    }
  }
});

export const { removeFilter, clearAll, applyFilter } = assetFilterSlice.actions;
export default assetFilterSlice.reducer;
