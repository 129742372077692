import { createSlice } from '@reduxjs/toolkit';
import { adminOnboarding } from 'api/api';

// Initial state
const initialState = {
  data: {},
  loading: false,
  error: null
};

// Slice
const AdminOnboardingSlice = createSlice({
  name: 'adminOnboarding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(adminOnboarding.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(adminOnboarding.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(adminOnboarding.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      });
  }
});

// Export actions and reducer
export const adminOnboardingActions = AdminOnboardingSlice.actions;
export default AdminOnboardingSlice.reducer;
