import { createSlice } from '@reduxjs/toolkit';
import { getTableData } from '../../api/api';

const initialState = {
  data: [],
  adminCostData: [],
  loading: false,
  error: ''
};

const tableDataSlice = createSlice({
  name: 'tableData',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTableData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTableData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        if (action.payload.type === 'adminCost') {
          state.adminCostData = action.payload;
        } else {
          state.data = action.payload;
        }
      })
      .addCase(getTableData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      });
  }
});

export default tableDataSlice.reducer;
