import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  priority: [],
  author: [],
  groupBy: [],
  date: {
    start: null,
    end: null
  }
};

const insightFilterSlice = createSlice({
  name: 'insightFilter',
  initialState,
  reducers: {
    applyFilter: (state, action) => {
      const { priority, author, groupBy, date } = action.payload;
      state.priority = priority;
      state.author = author;
      state.groupBy = groupBy;
      state.date = date;
    },
    removeFilter: (state, action) => {
      const { type, val } = action.payload;
      if (type === 'date') {
        state[type] = {
          start: null,
          end: null
        };
      } else state[type] = state[type]?.filter((item) => item !== val);
    },
    clearAll: (state) => {
      state.priority = [];
      state.author = [];
      state.groupBy = [];
      state.date = {
        start: null,
        end: null
      };
    }
  }
});

export const { applyFilter, clearAll, removeFilter } = insightFilterSlice.actions;
export default insightFilterSlice.reducer;
