import { createSlice } from '@reduxjs/toolkit';
import { getJobDetails } from '../../api/api';

const initialState = {
  data: {
    columns: [],
    data: [],
    page_size: 0,
    page_number: 0,
    totalCount: 0,
    totalPages: 0,
    source_info: {}
  },
  loading: true,
  error: ''
};

const JobDetailsSlice = createSlice({
  name: 'job-details',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getJobDetails.pending, (state) => {
        // state.data = {};
        state.loading = true;
      })
      .addCase(getJobDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.error = null;
          state.data = action?.payload || {
            columns: [],
            data: [],
            page_size: 0,
            page_number: 0,
            totalCount: 0,
            totalPages: 0,
            source_info: {}
          };
        }
      })
      .addCase(getJobDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = {
          columns: [],
          data: [],
          page_size: 0,
          page_number: 0,
          totalCount: 0,
          totalPages: 0,
          source_info: {}
        };
      });
  }
});

export default JobDetailsSlice.reducer;
