import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedin: false
};

const SSOLogin = createSlice({
  name: 'isSSOLogin',
  initialState,
  reducers: {
    disableLogin: (state) => {
      return { ...state, isLoggedin: false };
    },
    enableLogin: (state) => {
      return { ...state, isLoggedin: true };
    }
  }
});

export const { disableLogin, enableLogin } = SSOLogin.actions;
export default SSOLogin.reducer;
